import { FC } from 'react'
import { InfoPageWrapper, useInfoPage } from './infoPageWrapper'

type Props = {
  menuActive: boolean
  setMenuActive: (a: boolean) => void
}

const WhoWeAre: FC<Props> = (props) => {
  const pageInfo = useInfoPage(100)

  return (
    <InfoPageWrapper {...props} offset={100}>
      {((pageInfo?.description as string[]) ?? []).map((d, i) => (
        <p key={i} dangerouslySetInnerHTML={{ __html: d }}></p>
      ))}
      {/* <p dangerouslySetInnerHTML={{ __html: pageInfo.description[0] }}></p>
      <p dangerouslySetInnerHTML={{ __html: pageInfo.description[1] }}></p>
      <p dangerouslySetInnerHTML={{ __html: pageInfo.description[2] }}></p>
      {language === 'ru' ? (
        <p dangerouslySetInnerHTML={{ __html: pageInfo.description[3] }}></p>
      ) : undefined} */}
    </InfoPageWrapper>
  )
}

export default WhoWeAre
