import { FC } from 'react'
import { useInView } from 'react-intersection-observer'

import { Parameters } from 'types'
import { useLocal } from 'languages/use-local'
import { OpenUrlAniIcon } from '../Icons/OpenUrlAniIcon'
import {
  ProgramStyle,
  StyledLink,
  StyledIcon,
  Thema,
  RightColumn,
  LeftColumn,
} from './styled'

type Props = {
  content: Parameters
}
export const ProgramContainer: FC<Props> = ({ content }) => {
  const local = useLocal()
  const { ref: buttonRef, inView: isButtonInView } = useInView({
    threshold: 0,
  })

  if (!content) {
    return null
  }

  return (
    <ProgramStyle themaCode={content.themaCode}>
      <LeftColumn>
        <svg viewBox="0 0 16 9"></svg>
        <img
          src={content.photo}
          alt=""
          style={{ objectPosition: content.photoPosition }}
        />
      </LeftColumn>
      <RightColumn>
        <div>
          <Thema>{content.thema}</Thema>

          {!isButtonInView && (
            <a href={content.url} target="_blank">
              <h1>
                {content.title}
                <StyledIcon>
                  <OpenUrlAniIcon />
                </StyledIcon>
              </h1>
            </a>
          )}

          {isButtonInView && <h1>{content.title}</h1>}
          <h2>{content.info}</h2>

          <p
            dangerouslySetInnerHTML={{ __html: content.description as string }}
          />

          {!!content.url && (
            <StyledLink ref={buttonRef} href={content.url} target="_blank">
              {local.others.openShowPage}
              <OpenUrlAniIcon />
            </StyledLink>
          )}
        </div>
      </RightColumn>
    </ProgramStyle>
  )
}
