import { FC, HTMLAttributes } from 'react'
import styled, { keyframes } from 'styled-components'
export type IconType = FC<HTMLAttributes<SVGElement> & { className?: string }>
const arrowAnimation = keyframes`
  0% { transform: translate(0, 0)}
  50% { transform: translate(15%, -15%)}
  100% { transform: translate(0, 0)}
`
export const Svg = styled.svg`
  display: inline-block;
  width: 16px;
  overflow: visible;
  .arrow{
    animation-name: ${arrowAnimation};
   animation-duration: 1s;
   animation-iteration-count: infinite;
  }
`
export const OpenUrlAniIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 16 16"
    className={className}
  >
  <path d="M13 16H2C0.89543 16 0 15.1046 0 14V3C0 1.89543 0.89543 1 2 1H6V3H2V14H13V10H15V14C15 15.1046 14.1046 16 13 16Z"/>
  <path className="arrow" d="M7.7 9.707L6.29 8.293L12.583 2H9V0H16V7H14V3.415L7.7 9.707Z"/>
</Svg>
)
