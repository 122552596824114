import styled, { css } from 'styled-components'
import { chooseColor } from 'utils'
import { screen } from 'styles/mq'

export const ShareFloat = styled.a<{ themaColor: string; isInView: boolean }>`
  --transition-duration: 0.5s;
  --thema-color: ${(props) => chooseColor(props.themaColor)};

  display: block;
  position: sticky;
  bottom: 1rem;
  margin: 0 1.6rem;
  width: max-content;
  padding: 0.8rem;
  border: 1px solid currentColor;
  color: var(--thema-color);
  text-decoration: none;
  border-radius: 1rem;
  transition: transform var(--transition-duration), color 0.5s,
    background-color 0.3s;
  transform: translateY(-1.2rem);
  background-color: white;
  z-index: 1000;

  @media (max-width: ${screen.xs}) {
    margin-left: 1.2rem;
  }

  &:hover {
    color: white;
    background-color: var(--thema-color);
  }

  svg {
    margin: 0 0.2rem -0.2rem 0.2rem;
    transition: margin-left 0.2s;
  }

  path {
    fill: currentColor;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
  }

  ${(props) =>
    !props.isInView &&
    css`
      transform: translateX(calc(100vw - 2.6rem - 100%));

      @media (max-width: ${screen.xs}) {
        transform: translateX(calc(100vw - 2.2rem - 100%));
      }

      svg {
        margin-left: 0;
      }
    `}

  @media (min-width: ${screen.m}) {
    display: none;
  }
`
