import { HashRouter, Routes as RouterRoutes, Route } from 'react-router-dom'
import { useState } from 'react'
import Home from './components/Home'
import Program from './components/Program'
import WhoWeAre from './components/Program/whoWeAre'
import Platforms from './components/Program/platforms'
import SatelliteData from './components/Program/satelliteData'
import ContactInformation from './components/Program/contactInformation'
import Header from './components/Header'
import Menu from './components/Menu'
import { useLocal } from 'languages/use-local'

const Routes = () => {
  const [activeProgramCodes, setActiveProgramCodes] = useState<string[]>([])
  const [menuActive, setMenuActive] = useState(false)
  const local = useLocal()

  const openMenu = () => {
    setMenuActive(true)
    document.body.classList.add('body_noscroll')
    document.getElementsByTagName('html')[0].classList.add('body_noscroll')
  }
  const closeMenu = () => {
    setMenuActive(false)
    document.body.classList.remove('body_noscroll')
    document.getElementsByTagName('html')[0].classList.remove('body_noscroll')
  }

  return (
    <HashRouter>
      <RouterRoutes>
        <Route
          path="/"
          element={
            <Header
              openMenu={openMenu}
              closeMenu={closeMenu}
              menuActive={menuActive}
            />
          }
        >
          <Route
            path="/"
            element={
              <Menu
                activeProgramCodes={activeProgramCodes}
                menuActive={menuActive}
                closeMenu={closeMenu}
              />
            }
          >
            <Route
              index
              element={
                <Home
                  activeProgramCodes={activeProgramCodes}
                  onFilterChange={setActiveProgramCodes}
                  menuActive={menuActive}
                  setMenuActive={setMenuActive}
                />
              }
            />
            {/*<Route path="/" element={ <Home /> } />*/}

            <Route
              path="100"
              element={
                <WhoWeAre
                  menuActive={menuActive}
                  setMenuActive={setMenuActive}
                />
              }
            />
            <Route
              path="101"
              element={
                <Platforms
                  menuActive={menuActive}
                  setMenuActive={setMenuActive}
                />
              }
            />
            <Route
              path="102"
              element={
                <SatelliteData
                  menuActive={menuActive}
                  setMenuActive={setMenuActive}
                />
              }
            />
            <Route
              path="103"
              element={
                <ContactInformation
                  menuActive={menuActive}
                  setMenuActive={setMenuActive}
                />
              }
            />
            <Route
              path=":id"
              element={
                <Program
                  menuActive={menuActive}
                  setMenuActive={setMenuActive}
                />
              }
            />
          </Route>
        </Route>
      </RouterRoutes>
    </HashRouter>
  )
}

export default Routes
