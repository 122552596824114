import styled, { css } from 'styled-components'
import { chooseColor } from 'utils'
import { screen } from 'styles/mq'
import { colors } from 'styles/colors'

export const SwiperContainer = styled.div`
  position: relative;
  top: 84px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% - 84px);
  z-index: 3;

  @media (max-width: ${screen.m}) {
    top: 84px;
    width: calc(100% + 16px);
    left: -8px;
    height: auto;
    z-index: 1;
  }
  @media(max-width: ${screen.s}){
    width: calc(100% + 16px);
    left: -8px;
  }
  @media (max-width: ${screen.xs}) {
    top: 64px;
    width: calc(100% + 24px);
    left: -12px;
  }
  .swiper {
    padding: 20px 0;
    padding: 20px;
    height: 100%;
    @media (max-width: ${screen.m}) {
      padding: 8px;
      height: auto;
    }
    @media (max-width: ${screen.s}) {
      padding: 0px;
    }
    @media (max-width: ${screen.xs}) {
      padding: 0px;
    }
  }
  .swiper-wrapper {
    max-height: 100%;
    @media (max-width: ${screen.m}) {
      max-height: none;
      height: auto;
    }
  }
  .swiper-slide {
    max-height: 100%;
    height: 100%;
    @media (max-width: ${screen.m}) {
      max-height: none;
      height: auto;
    }
  }
  .swiper-button-prev {
    display: none;
    top: 28.125vw;
    margin-top: -22px;
    left: 20px;
    @media (max-width: ${screen.s}) {
      left: 12px;
    }
    border: 1px solid #dee5ec;
    border-radius: 10px;
    color: #dee5ec;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px;
    ::after {
      font-size: 14px;
    }
    @media (max-width: ${screen.m}) {
      display: flex;
    }
  }
  .swiper-button-next {
    display: none;
    top: 28.125vw;
    margin-top: -22px;
    right: 20px;
    @media (max-width: ${screen.s}) {
      right: 12px;
    }
    border: 1px solid #dee5ec;
    border-radius: 10px;
    color: #dee5ec;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px;
    ::after {
      font-size: 14px;
    }
    @media (max-width: ${screen.m}) {
      display: flex;
    }
  }
`

export const ProgramStyle = styled.div<{ themaCode?: string }>`
  ${(props) =>
    props.themaCode &&
    css`
      --thema-color: ${chooseColor(props.themaCode)};
    `}

  position: relative;
  width: calc(100% - 316px);
  height: 100%;
  max-height: 912px;
  border-radius: 28px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  color: ${colors.black};

  @media (max-width: ${screen.xxl}) {
    max-height: 792px;
    width: calc(100% - 288px);
    right: 348px;
    left: 0;
  }
  @media (max-width: ${screen.xl}) {
    width: calc(100% - (100vw / 5));/*calc(100% - 288px)*/
  }
  @media(max-width: 1240px){
    width: calc(100% - (100vw / 4));
  }
  @media(max-width: 1024px){
    width: 100%;
    right: auto;
    left: auto;
    max-height: 692px;
  }
  @media (max-width: ${screen.m}) {
    display: block;
    top: 0;
    height: auto;
    max-height: none;
  }

  @media (min-width: ${screen.m}) {
    box-shadow: 0 0 20px rgba(56, 56, 56, 0.15);
  }
`

export const LeftColumn = styled.div`
  position: relative;
  width: 50%;
  @media (max-width: ${screen.m}) {
    position: relative;
    width: 100%;
    height: auto;
  }
  > svg {
    position: absolute;
    width: 0%;
    @media (max-width: ${screen.m}) {
      position: relative;
      width: 100%;
    }
  }
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    @media (max-width: ${screen.m}) {
      position: absolute;
      left: 0;
    }
  }
`

export const RightColumn = styled(LeftColumn)`
  padding: 50px 30px 40px 20px;

  @media (max-width: ${screen.m}) {
    padding: 20px 30px 40px 20px;
  }

  > div {
    height: 100%;
    padding-right: 16px;
    overflow-y: auto;

    > a {
      text-decoration: none;
    }
    > h1,
    a h1 {
      font-size: 42px;
      line-height: 50px;
      @media(max-width: ${screen.xxl}){
        font-size: 36px;
        line-height: 42px;
      }
      text-transform: uppercase;
      margin-top: -5px;
      margin-bottom: -5px;
      color: ${colors.black};
      span {
        display: inline-block;
        line-height: 0;
        padding: 0 0 0 12px;
        transform: translateY(-80%);
      }
      @media (max-width: ${screen.s}) {
        font-size: 28px;
        line-height: 36px;
        margin-top: 0;
        span {
          transform: translateY(-60%);
        }
      }
      @media (max-width: ${screen.xs}) {
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 12px;
        span {
          transform: translateY(-40%);
        }
      }
    }
    > h2 {
      font-size: 18px;
      @media(max-width: ${screen.xxl}){
        font-size: 16px;
      }
      margin: 6px 0 12px;
      opacity: 0.7;
      text-transform: capitalize;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      @media(max-width: ${screen.xxl}){
        font-size: 16px;
      }
    }
    ul {
      list-style-type: none;
      padding-left: 0;
    }
  }
`

export const Thema = styled.span`
  display: block;
  margin: 0 0 12px 0;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  @media(max-width: ${screen.xxl}){
    font-size: 21px;
  }
  @media (max-width: ${screen.s}) {
    color: var(--thema-color);
    font-size: 19px;
  }
`

export const AboutContainer = styled.div`
  position: relative;
  top: 84px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% - 84px);
  z-index: 3;
  @media (max-width: ${screen.m}) {
    top: 84px;
    width: calc(100% + 16px);
    left: -8px;
    height: auto;
    z-index: 1;
  }
  @media (max-width: ${screen.xs}) {
    width: calc(100% + 24px);
    left: -12px;
    top: 64px;
  }
  h1 {
    margin-bottom: 12px;
  }
  > div {
    padding: 20px 0;
    padding: 20px;
    height: 100%;
    @media (max-width: ${screen.m}) {
      padding: 8px;
      height: auto;
    }
    @media (max-width: ${screen.xs}) {
      padding: 0px;
    }
  }
  ul li a {
    margin: 0;
  }

  ul.social_media,
  ul.contacts,
  .platforms,
  ul.satellite_data {
    position: relative;
      font-size: 21px;
    @media (max-width: ${screen.xxl}) {
      font-size: 18px;
    }
    @media (max-width: ${screen.xs}) {
      font-size: 14px;
    }
    h3 {
      font-size: 24px;
      font-weight: 300;
      @media (max-width: ${screen.xxl}) {
        font-size: 21px;
      }
    }
    li {
      padding: 4px 0;
    }
    li p {
      margin: 0;
    }
    li a {
      display: block;
      position: relative;
      margin: 0;
      padding: 0;
      text-decoration: none;
      font-size: 18px;
      line-height: 26px;
      @media (max-width: ${screen.xxl}) {
        font-size: 16px;
        line-height: 24px;
      }
      color: ${colors.black};
      &:hover {
        color: ${colors.plum};
      }
      svg {
        position: absolute;
        top: 3px;
        fill: ${colors.plum};
      }
      span {
        position: relative;
        display: inline-block;
        padding-left: 32px;
      }
    }
  }
`

export const StyledLink = styled.a`
  display: inline-block;
  padding: 7px 40px 6px;
  font-size: 18px;
  line-height: 26px;
  @media (max-width: ${screen.xxl}) {
    font-size: 16px;
    line-height: 24px;
  }
  text-decoration: none;
  border-radius: 18px;
  margin: 40px 0;
  color: var(--thema-color);
  border: 2px solid var(--thema-color);

  svg {
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0 8px;
    width: 0.8em;
    fill: var(--thema-color);
  }

  :hover {
    background-color: var(--thema-color);
    color: ${colors.white};

    svg {
      fill: ${colors.white};
    }
  }

  @media (max-width: ${screen.m}) {
    display: none;
  }
`

export const StyledIcon = styled.span`
  fill: var(--thema-color);

  @media (max-width: ${screen.m}) {
    display: none !important;
  }
`
