import { FC, useState, useEffect } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import {
  HeaderTag,
  MenuOpen,
  MenuClose,
  ProgramClose,
  Logo,
  HomeIconContainer,
  LangToggles,
} from './styled'
import { language } from 'config'
import { Logo as LogoRu } from './logo_ru'
import { Logo as LogoEn } from './logo_en'
import { MenuIcon } from '../Icons/MenuIcon'
import { HomeIcon } from '../Icons/HomeIcon'
import { useLocal } from 'languages/use-local'

type Props = {
  openMenu: () => void
  closeMenu: () => void
  menuActive: boolean
}

const Header: FC<Props> = ({ openMenu, closeMenu, menuActive }) => {
  const local = useLocal()
  const location = useLocation()
  const [closeActive, setCloseActive] = useState(false)

  useEffect(() => {
    if (location.pathname === '/') {
      setCloseActive(true)
    } else {
      setCloseActive(false)
    }
  }, [location.pathname])

  return (
    <>
      <HeaderTag>
        <a href="https://www.currenttime.tv" target="_blank">
          <Logo as={language === 'en' ? LogoEn : LogoRu} />
        </a>
        <div>
          <HomeIconContainer isActive={location.pathname != '/'}>
            <Link to={''}>
              <HomeIcon />
            </Link>
          </HomeIconContainer>
          <LangToggles>
            {language === 'ru' ? <span>РУ</span> : <a href={local.url}>РУ</a>}
            &nbsp;/&nbsp;
            {language === 'en' ? <span>EN</span> : <a href={local.url}>EN</a>}
          </LangToggles>
          <MenuOpen onClick={openMenu} isActive={menuActive}>
            <MenuIcon />
          </MenuOpen>
          <MenuClose
            onClick={closeMenu}
            isActive={menuActive}
            isCloseActive={closeActive}
          >
            <svg viewBox="0 0 40 40">
              <path d="M6,6 36,36z" />
              <path d="M6,36 36,6z" />
            </svg>
          </MenuClose>
          <ProgramClose isActive={menuActive} isCloseActive={closeActive}>
            <Link to={''}>
              <svg viewBox="0 0 40 40">
                <path d="M6,6 36,36z" />
                <path d="M6,36 36,6z" />
              </svg>
            </Link>
          </ProgramClose>
        </div>
      </HeaderTag>
      <Outlet />
    </>
  )
}
export default Header
