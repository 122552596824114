const queryLang = new URLSearchParams(document.location.search).get('lang');
const lang = queryLang && ['en', 'ru'].includes(queryLang) ? queryLang : '';

export const language = (process.env.REACT_APP_LANG || lang || 'en') as
  | 'en'
  | 'ru'; /*CHOOSE LANGUAGE*/

type Sections = {
  id: number;
  name: string;
  code: string;
  color: string;
}[];

export const sections = [
  {
    id: 1,
    name: 'Current Affairs',
    code: 'current_affairs',
    color: '#ff0055',
  },
  {
    id: 2,
    name: 'Society',
    code: 'society',
    color: '#2d1996',
  },
  {
    id: 3,
    name: 'Travel',
    code: 'travel',
    color: '#a5abb1',
  },
  {
    id: 4,
    name: 'News / Analytics',
    code: 'news_analytics',
    color: '#0ecae3',
  },
  {
    id: 5,
    name: 'Documentary series',
    code: 'documentary',
    color: '#009bac',
  },
  {
    id: 6,
    name: 'History',
    code: 'history',
    color: '#102c44',
  },
  /*{
    id: 6,
    name: 'About',
    code: 'about',
    color: '#fff',
  },*/
] as Sections;
