import {
  FC,
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'

import { language } from 'config'
import en from 'languages/en'
import ru from 'languages/ru'
import { Texts } from './types'
import { localizeColumns } from './helpers'

type ContextType = Texts

const locales = {
  en: {
    texts: en,
  },
  ru: {
    texts: ru,
  },
}

const locale = locales[language]
const LocalContext = createContext<ContextType>(locale.texts)

export const LocalContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [texts, setTexts] = useState<Texts>(locale.texts)

  const fetchData = async () => {
    const programs = await fetch(`${process.env.PUBLIC_URL}/data/data.json`)
      .then((res) => res.text())
      .then((res) => {
        const json = JSON.parse(res.replaceAll(/\n/g, ''))
        return [...localizeColumns(json.data), ...en.about]
      })
    setTexts((t) => ({ ...t, programs }))
  }

  useEffect(() => {
    void fetchData()
  }, [])

  return <LocalContext.Provider value={texts}>{children}</LocalContext.Provider>
}

export const useLocal = () => {
  const context = useContext(LocalContext)
  return context
}
