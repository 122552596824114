import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { screen } from 'styles/mq'
export type IconType = FC<HTMLAttributes<SVGElement> & { className?: string }>
export const Svg = styled.svg`
  display: block;
  width: 20px;

  @media (min-width: ${screen.m}) {
    width: 22px;
  }
`
export const HomeIcon: IconType = ({ className = '' }) => (
  <Svg viewBox="0 0 20 20" className={className}>
    <path
      fill="currentColor"
      d="M1.66602 10.8334L9.41018 3.0892C9.7356 2.76389 10.2631 2.76389 10.5885 3.0892L18.3327 10.8334H16.666V17.5C16.666 17.9603 16.2929 18.3334 15.8327 18.3334H11.666V12.5H8.33268V18.3334H4.16602C3.70578 18.3334 3.33268 17.9603 3.33268 17.5V10.8334H1.66602Z"
    />
  </Svg>
)
