import { createGlobalStyle } from 'styled-components'
import { screen } from './mq'
import { colors } from './colors'

export const GlobalStyle = createGlobalStyle`
@font-face{
  font-family:"RobotoCondensed";
  src:url("https://docs.rferl.org/Branding/CDN/sources/fonts/Roboto/RobotoCondensed_Regular_v2.137.woff2") format("woff2"), url("https://docs.rferl.org/Branding/CDN/sources/font/Roboto/RobotoCondensed_Regular_v2.137.woff") format("woff");
  font-weight:400;
  font-style:normal;
}

@font-face{
  font-family:"RobotoCondensed";
  src:url("https://docs.rferl.org/Branding/CDN/sources/fonts/Roboto/RobotoCondensed_Bold_v2.137.woff2") format("woff2"), url("https://docs.rferl.org/Branding/CDN/sources/font/Roboto/RobotoCondensed_Bold_v2.137.woff") format("woff");
  font-weight:700;
  font-style:normal;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  font-family:"RobotoCondensed",Arial,"Arial Unicode MS",Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  color: ${ colors.black };
}

.body_noscroll {
  @media(max-width: 1024px){
    overflow: hidden;
  }
}

#root {
  width: 100%;
  max-width: 1600px;
  margin: auto;
  padding: 0px 20px;
  height: 100%;
  @media(max-width: ${ screen.xxl}){
    max-width: 1440px;
  }
  @media(max-width: ${ screen.m }){
    padding: 0 8px;
  }
  @media(max-width: ${ screen.xs }){
    padding: 0 12px;
  }
}
`
