import styled from 'styled-components'
import { screen } from 'styles/mq'
import { colors } from 'styles/colors'

export const HeaderTag = styled.header`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 100%;
  max-width: 1600px;
  height: 104px;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  z-index: 2;

  @media (max-width: ${screen.xxl}) {
    max-width: 1440px;
  }
  @media (max-width: ${screen.m}) {
    height: 84px;
  }

  @media (max-width: ${screen.xs}) {
    height: 64px;
  }

  > a {
    display: block;
    padding: 28px 20px;
    height: 48px;
    height: 100%;
    @media (max-width: ${screen.m}) {
      padding: 20px;
    }
    @media (max-width: ${screen.xs}) {
      padding: 14px 20px 14px 12px;
    }
    svg {
      height: 48px;
      height: 100%;
      fill: ${colors.plum};
    }
  }

  > div {
    display: flex;
    align-items: center;

    > div {
      margin-right: 28px;
      font-size: 18px;

      @media (max-width: ${screen.m}) {
        margin-right: 16px;
        font-size: 16px;
        &:last-child {
          margin-right: 8px;
        }
      }

      @media (max-width: ${screen.xs}) {
        margin-right: 12px;
        font-size: 15px;
      }

      a {
        text-decoration: none;
        color: ${colors.black};
      }
    }
  }
`

export const LangToggles = styled.div`
  a{
    display: inline-block;
    width: 24.22px;
    width: 19.86px;
    @media (max-width: ${screen.m}) {
      width: 21.34px;
      width: 17.86px;
    }
    @media (max-width: ${screen.xs}) {
      width: 20.02px;
      width: 16.75px;
    }
  }
  span,
  a:hover,
  a:focus {
    color: ${colors.plum};
    font-weight: 600;
  }

  span {
    font-weight: bold;
  }
`

export const MenuOpen = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? 'none' : 'block')};
  font-size: 0;
  cursor: pointer;
`

export const MenuClose = styled.div<{
  isActive: boolean
  isCloseActive: boolean
}>`
  display: ${(props) => (props.isActive ? 'block' : 'none')};

  /*@media (min-width: ${screen.l}) {
    display: ${(props) =>
      props.isCloseActive && props.isActive ? 'block' : 'none'};
  }*/
  @media (min-width: 1025px) {
    display: ${(props) =>
      props.isCloseActive && props.isActive ? 'block' : 'none'};
  }

  width: 40px;
  height: 40px;
  cursor: pointer;

  svg {
    stroke: ${colors.black};
    stroke-width: 2px;
    stroke-linecap: round;
  }
`
export const ProgramClose = styled.div<{
  isActive: boolean
  isCloseActive: boolean
}>`
  display: none;
  /*@media (min-width: ${screen.l}) {
    display: ${(props) => (props.isCloseActive ? 'none' : 'block')};
  }*/
  @media (min-width: 1025px) {
    display: ${(props) => (props.isCloseActive ? 'none' : 'block')};
  }
  width: 40px;
  height: 40px;
  margin-top: -3px;
  cursor: pointer;

  svg {
    stroke: ${colors.black};
    stroke-width: 2px;
    stroke-linecap: round;
  }
`
export const Logo = styled.div`
  height: 48px;
  fill: ${colors.plum};
`
export const HomeIconContainer = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  margin-bottom: 0.2rem;

  a {
    transition: color 0.3s;
  }

  a:hover,
  a:focus {
    color: ${colors.plum};
  }
`
