import { Texts } from './types'

export const sectionsNames = [
  'All',
  'Current Affairs',
  'Society',
  'Travel',
  'News / Analytics',
  'Documentary series',
  'History',
]

const texts: Texts = {
  title: 'Catalog 2024',
  url: 'https://www.currenttime.tv/a/28791625.html', //ru version for changing
  sections: sectionsNames,
  programs: [],
  about: [
    {
      //About
      id: 100,
      title: 'Who We Are',
      photo:
        'https://gdb.currenttime.tv/5b28a965-0197-4339-a4d3-4ad5f51e642e_w1280_n.jpg',
      photoPosition: 'center center',
      thema: 'About',
      themaCode: 'about',
      description: [
        'Current Time (Настоящее Время) is a 24/7 TV & digital network targeting Russian speakers living around the globe. Current Time offers an objective and fresh alternative to Kremlin-controlled media – providing live news, spin-free analysis, and engaging feature programming with an accent on people, not politics.',
        'Funded by the U.S. Congress, and led by Radio Free Europe/Radio Liberty in cooperation with Voice of America, Current Time has gathered top-rate independent journalists to deliver the news from Russia, Ukraine, Central Asia, the Baltics, Europe, and the United States. And with 100 new titles a year, Current Time offers the largest compendium of Russian-language documentaries – many of them never shown on the mainstream Russian market.',
        'We round out our programming with shows on business start-ups, education, media literacy, cooking and travel, and a rare look at the unexplored people and places of the Russian-speaking world.',
      ],
      url: '',
      info: '',
    },
    {
      id: 101,
      title: 'Platforms',
      photo:
        'https://gdb.currenttime.tv/9a0dc41d-96e6-4304-b3f5-30fbc7d2e0b0_w1280_n.jpg',
      photoPosition: 'center center',
      thema: 'About',
      themaCode: 'about',
      description: [
        '24-hour streaming channel Current Time TV',
        'Daily newsletter at 07:40',
        'Mobile application',
        'Social Media',
      ],
      url: '',
      info: '',
    },
    {
      id: 102,
      title: 'Satellite Data',
      photo:
        'https://gdb.currenttime.tv/066ed60e-a90e-4baf-bb7c-800b96a75188_w1280_n.jpg',
      photoPosition: 'bottom center',
      thema: 'About',
      themaCode: 'about',
      description: [
        'Satellite: Hot Bird 13B 13° East <br> Frequency: 12,226 Ghz <br> Modulation speed: 27500 MS <br> Polarization: Vertical FEC: ¾ <br> Virtual channel: 200 <br> Video PID: 1760 <br> Audio PID: 1720',
        '<h3>FROM 19.00 TO 7.00 (MOSCOW)</h3> Satellite: ASTRA 5B 31,5° East <br> Frequency: 11,817 Ghz (Turan TV) <br> Modulation speed: 27500 MS <br> Polarization: Vertical FEC: ¾ <br> Satellite: Eutelsat 36B 36° East <br> Frequency: 11,212 Ghz <br> Modulation speed: 14400 MS <br> Polarization: Horizontal FEC: 3/5 <br> Virtual channel: 5002 <br> Video PID: 1860 <br> Audio PID: 1820',
      ],
      url: '',
      info: '',
    },
    {
      id: 103,
      title: 'Contact Information',
      photo:
        'https://gdb.currenttime.tv/bcc31c80-4fb3-41c9-a385-37cdee06ac4e_w1280_n.jpg',
      photoPosition: 'center center',
      thema: 'About',
      themaCode: 'about',
      description: [
        'distribution@currenttime.tv',
        'aliyevk@currenttime.tv',
        'www.currenttime.tv',
      ],
      url: '',
      info: '',
    },
  ],
  others: {
    openShowPage: 'open show page',
  },
  socialMedia: {
    facebook: 'facebook.com/currenttimetv',
    youtube: 'youtube.com/user/currenttimetv',
    vk: 'vk.com/currenttime',
    twitter: 'twitter.com/currenttimetv',
    telegram: 'telegram.me/currenttime',
    instagram: 'instagram.com/currenttime.tv',
    ok: 'ok.ru/currenttime',
  },
}

export default texts
