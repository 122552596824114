import { FC, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SwiperRaw from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import { ProgramContainer } from './programContainer'
import { useLocal } from 'languages/use-local'
import { SwiperContainer } from './styled'
import { FloatingShareButton } from './floating-share-button'
import { useMediaQuery } from 'hooks/useMediaQuery'

type Props = {
  menuActive: boolean
  setMenuActive: (a: boolean) => void
}

const Program: FC<Props> = ({ setMenuActive }) => {
  const local = useLocal()
  const params = useParams()
  const navigate = useNavigate()
  const currentProgramIndex = local.programs.findIndex(
    (p) => p.id.toString() === params.id
  )
  const currentProgram = local.programs[currentProgramIndex]
  const isMobile = useMediaQuery(['(max-width: 1024px)'], [true], false)

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const changeSlide = (swiper: SwiperRaw) => {
    const newProgram = local.programs[swiper.activeIndex]

    if (newProgram) {
      navigate(`/${newProgram.id}`)
    }
  }

  useEffect(() => {
    if (isMobile) {
      setMenuActive(false)
      document.body.classList.remove('body_noscroll')
      document.querySelector('html')?.classList.remove('body_noscroll')
    } else {
      setMenuActive(true)
      document.body.classList.add('body_noscroll')
      document.querySelector('html')?.classList.add('body_noscroll')
    }
  }, [isMobile])

  if (!currentProgram) {
    return null
  }

  return (
    <SwiperContainer>
      <Swiper
        draggable
        navigation
        autoHeight={true}
        breakpoints={{
          835: {
            autoHeight: false,
          },
        }}
        modules={[Navigation]}
        spaceBetween={20}
        key={currentProgramIndex}
        initialSlide={currentProgramIndex}
        noSwipingClass={'noSwipe'}
        onSlideChangeTransitionStart={scrollToTop}
        onSlideChangeTransitionEnd={changeSlide}
      >
        {local.programs.map((program) => {
          return (
            <SwiperSlide key={program.id}>
              <ProgramContainer content={program} />
            </SwiperSlide>
          )
        })}
      </Swiper>

      <FloatingShareButton currentProgram={currentProgram} />
    </SwiperContainer>
  )
}

export default Program
