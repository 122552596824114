import { Link } from 'react-router-dom'
import { FC, useEffect } from 'react'
import { AnimatePresence, Variants } from 'framer-motion'

//import { MainMenuItem } from './mainMenuItem'
import Filters from '../../components/Filters'
import { useLocal } from 'languages/use-local'
import {
  HomeTitle,
  Hr,
  MainMenuContainer,
  MainMenuItem,
  MainMenuPhoto,
} from './styled'

type Props = {
  activeProgramCodes: string[]
  onFilterChange: (p: string[]) => void
  menuActive: boolean
  setMenuActive: (a: boolean) => void
}

const containerVariants: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const variants: Variants = {
  initial: {
    opacity: 0,
    translateY: '-1rem',
  },
  enter: {
    opacity: 1,
    translateY: 0,
    transition: {
      type: 'tween',
      easings: ['easeInOut'],
    },
  },
  exit: {
    opacity: 0,
    translateY: '3rem',
    transition: {
      type: 'tween',
      easings: ['easeInOut'],
    },
  },
}

const Home: FC<Props> = ({
  activeProgramCodes,
  onFilterChange,
  menuActive,
  setMenuActive,
}) => {
  const local = useLocal()

  useEffect(() => {
    setMenuActive(false)
    document.body.classList.remove('body_noscroll')
    document.getElementsByTagName('html')[0].classList.remove('body_noscroll')
  }, [])

  const isSectionActive = (code: string) =>
    activeProgramCodes.length === 0 || activeProgramCodes.includes(code)

  const changeFilter = (code: string) => {
    onFilterChange(
      activeProgramCodes.includes(code)
        ? activeProgramCodes.filter((c) => c !== code)
        : [code]
    )
  }

  const changeToAll = () => {
    onFilterChange([])
  }

  return (
    <>
      <HomeTitle>{local.title}</HomeTitle>
      <Filters
        onClickAll={changeToAll}
        onClickFilter={changeFilter}
        activeProgramCodes={activeProgramCodes}
      />
      <AnimatePresence mode="wait">
        <MainMenuContainer
          $isMenuActive={menuActive}
          key={activeProgramCodes.join()}
          variants={containerVariants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {local.programs
            .filter((p) => isSectionActive(p.themaCode))
            .map((program) => (
              <MainMenuItem
                key={`${program.id}-${activeProgramCodes}`}
                variants={variants}
              >
                <Link to={`${program.id}`}>
                  <MainMenuPhoto>
                    <svg viewBox="0 0 100 100"></svg>
                    <div>
                      <img src={program.photo} alt={program.title} />
                    </div>
                  </MainMenuPhoto>
                  <Hr themaCode={program.themaCode} />
                  <p>
                    <span>{program.thema}</span>
                    {program.title}
                  </p>
                </Link>
              </MainMenuItem>
            ))}
        </MainMenuContainer>
      </AnimatePresence>
    </>
  )
}
export default Home
