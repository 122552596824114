import styled, { css } from 'styled-components'
import { colors } from 'styles/colors'
export const FiltersContainer = styled.div<{ isActive: boolean }>`
  padding: ${(props) => (props.isActive ? '0 26px' : '0')};
  position: relative;

  .swiper {
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 20px 0;
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
  }

  .swiper-pointer-events {
    touch-action: pan-y;
  }

  .swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    position: relative;
    width: 100%;
    overflow: visible;
  }

  .swiper-slide {
    width: max-content;
    padding-right: 16px;
    transform: ${(props) =>
      props.isActive ? 'translateX(16px) !important' : 'none'};
  }
  .swiper-slide:last-child {
    padding-right: ${(props) => (props.isActive ? '32px' : '0')};
  }
  .swiper-slide:first-child {
    //padding-left: ${(props) => (props.isActive ? '16px' : '0')};
  }

  .swiper-button-prev,
  .swiper-button-next {
    height: 100%;
    top: 20px;
    width: auto;
    margin-top: 0;
    pointer-events: none;
    z-index: 2;

    &::after {
      pointer-events: auto;
    }
  }

  .swiper-button-prev {
    display: block;
    left: 0;
    position: absolute;
    text-align: left;
    width: 42px;
    height: 27px;
    /* background: linear-gradient(
      to right,
      ${colors.white},
      ${colors.white},
      transparent
    ); */
    cursor: pointer;

    &::after {
      position: relative;
      top: 4px;
      font-family: swiper-icons;
      font-size: 18px;
      content: 'prev';
      color: ${colors.black};
    }
  }

  .swiper-button-next {
    display: block;
    right: 0;
    position: absolute;
    text-align: right;
    width: 42px;
    height: 27px;
    /* background: linear-gradient(
      to left,
      ${colors.white},
      ${colors.white},
      transparent
    ); */
    cursor: pointer;

    &::after {
      position: relative;
      top: 4px;
      font-family: swiper-icons;
      font-size: 18px;
      content: 'next';
      color: ${colors.black};
    }
  }

  .swiper-button-disabled {
    opacity: 0.3;
  }
  .swiper-button-lock {
    display: none;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  overflow: visible;
`
export const ButtonRight = styled.div`
  position: absolute;
  left: 0px;
  text-align: left;
  width: 52px;
  width: 42px;
  height: 27px;
  background: linear-gradient(
    to right,
    ${colors.white},
    ${colors.white},
    transparent
  );
  cursor: pointer;
  z-index: 1;
  @media (min-width: 772px) {
    display: none;
  }
`
export const ButtonLeft = styled.div`
  position: absolute;
  right: 0px;
  text-align: right;
  width: 52px;
  width: 42px;
  height: 27px;
  background: linear-gradient(
    to left,
    ${colors.white},
    ${colors.white},
    transparent
  );
  cursor: pointer;
  z-index: 1;
  @media (min-width: 772px) {
    display: none;
  }
`
export const Button = styled.span<{
  isActive: boolean
  sectionColor?: string
}>`
  display: block;
  margin: -10px 16px -10px 0;
  cursor: pointer;
  width: max-content;
  padding: 10px 0;
  flex-shrink: 0;
  background-color: ${colors.white};

  > span {
    display: block;
    position: relative;
    font-family: 'RobotoCondensed', Arial, 'Arial Unicode MS', Helvetica,
      sans-serif;
    font-size: 15px;
    line-height: 18px;
    padding: 4px 12px 4px 8px;
    border-radius: 14px;
    border: none;
    box-shadow: 0 0 10px -2px rgba(56, 56, 56, 0.15);
    cursor: pointer;
    flex-shrink: 0;
    background-color: ${colors.white};

    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      transform: translateY(1px);
      background: ${colors.white};
      border-radius: 50%;
      margin-right: 8px;
      background-color: ${(props) => props.sectionColor};
    }
  }

  &.buttonAll {
    > span {
      padding: 4px 12px 4px 14px;
      background-color: ${(props) =>
        props.isActive ? colors.black : colors.white};
      color: ${(props) => (props.isActive ? colors.white : colors.black)};

      &::before {
        background-color: ${(props) =>
          props.isActive ? colors.white : colors.black};
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.isActive &&
    css`
      > span {
        color: ${colors.white};
        background-color: ${props.sectionColor};

        &::before {
          background-color: ${colors.white};
        }
      }
    `}
`
