import { FC } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import { OpenUrlAniIcon } from 'components/Icons/OpenUrlAniIcon'
import { Texts } from 'languages/types'
import { useLocal } from 'languages/use-local'
import { ShareFloat } from './styled'

type Props = {
  currentProgram?: Texts['programs'][number]
}

export const FloatingShareButton: FC<Props> = ({ currentProgram }) => {
  const local = useLocal()
  const { ref, inView } = useInView()

  return !!currentProgram ? (
    <>
      <ShareFloat
        isInView={inView}
        themaColor={currentProgram.themaCode}
        href={currentProgram.url}
        target="_blank"
        rel="noreferrer"
      >
        <motion.span
          animate={inView ? {} : { width: 0 }}
          transition={{ type: 'tween', duration: 0.4, easings: ['easeInOut'] }}
        >
          {local.others.openShowPage}
        </motion.span>{' '}
        <OpenUrlAniIcon />
      </ShareFloat>
      <span ref={ref}></span>
    </>
  ) : null
}
