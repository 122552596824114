import { FC } from 'react'

import { useLocal } from 'languages/use-local'
import {
  FacebookIcon,
  YoutubeIcon,
  VKIcon,
  TwitterIcon,
  TelegramIcon,
  InstagramIcon,
  OKIcon,
} from '../Icons/SocialIcons'
import { OpenUrlIcon } from '../Icons/OpenUrlIcon'
import { InfoPageWrapper, useInfoPage } from './infoPageWrapper'

type Props = {
  menuActive: boolean
  setMenuActive: (a: boolean) => void
}

const Platforms: FC<Props> = (props) => {
  const local = useLocal()
  const programInfo = useInfoPage(101)

  return (
    <InfoPageWrapper {...props} offset={101}>
      <div className="platforms">
        <ul>
          <li>
            <a href="https://www.currenttime.tv/live">
              <OpenUrlIcon />
              <span
                dangerouslySetInnerHTML={{
                  __html: programInfo.description[0],
                }}
              ></span>
            </a>
          </li>
          <li>
            <a href="https://www.currenttime.tv/semsorok">
              <OpenUrlIcon />
              <span
                dangerouslySetInnerHTML={{
                  __html: programInfo.description[1],
                }}
              ></span>
            </a>
          </li>
          <li>
            <a href="https://www.currenttime.tv/app">
              <OpenUrlIcon />
              <span
                dangerouslySetInnerHTML={{
                  __html: programInfo.description[2],
                }}
              ></span>
            </a>
          </li>
        </ul>
        <h3
          dangerouslySetInnerHTML={{
            __html: programInfo.description[3],
          }}
        ></h3>
        <ul className="social_media">
          <li>
            <a
              href={'https://www.' + local.socialMedia.facebook}
              target="_blank"
            >
              <FacebookIcon />
              <span>{local.socialMedia.facebook}</span>
            </a>
          </li>
          <li>
            <a
              href={'https://www.' + local.socialMedia.facebook}
              target="_blank"
            >
              <YoutubeIcon />
              <span>{local.socialMedia.youtube}</span>
            </a>
          </li>
          <li>
            <a
              href={'https://www.' + local.socialMedia.facebook}
              target="_blank"
            >
              <VKIcon />
              <span>{local.socialMedia.vk}</span>
            </a>
          </li>
          <li>
            <a
              href={'https://www.' + local.socialMedia.facebook}
              target="_blank"
            >
              <TwitterIcon />
              <span>{local.socialMedia.twitter}</span>
            </a>
          </li>
          <li>
            <a
              href={'https://www.' + local.socialMedia.facebook}
              target="_blank"
            >
              <TelegramIcon />
              <span>{local.socialMedia.telegram}</span>
            </a>
          </li>
          <li>
            <a
              href={'https://www.' + local.socialMedia.facebook}
              target="_blank"
            >
              <InstagramIcon />
              <span>{local.socialMedia.instagram}</span>
            </a>
          </li>
          <li>
            <a
              href={'https://www.' + local.socialMedia.facebook}
              target="_blank"
            >
              <OKIcon />
              <span>{local.socialMedia.ok}</span>
            </a>
          </li>
        </ul>
      </div>
    </InfoPageWrapper>
  )
}

export default Platforms
