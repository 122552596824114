import { language } from 'config'
import { Program } from './types'

type RawData = {
  id: string
  title_ru: string
  title_en: string
  photo: string
  photoPosition: string
  thema_en: string
  thema_ru: string
  themaCode: string
  description_en: string
  description_ru: string
  url: string
  info_en: string
  info_ru: string
}

type Mappings = {
  [l in typeof language]: Partial<{
    [k in keyof Program]: keyof RawData
  }>
}

const MAPPINGS: Mappings = {
  en: {
    title: 'title_en',
    description: 'description_en',
    info: 'info_en',
    thema: 'thema_en',
  },
  ru: {
    title: 'title_ru',
    description: 'description_ru',
    info: 'info_ru',
    thema: 'thema_ru',
  },
}

export const localizeColumns = (data: RawData[]): Program[] => {
  const mappings = MAPPINGS[language]
  return data.map((row) => ({
    ...row,
    ...Object.keys(mappings).reduce(
      (acc, key) => ({
        ...acc,
        [key as keyof Program]:
          row[mappings[key as keyof Program] as keyof RawData],
      }),
      {} as Partial<{ [k in keyof Program]: keyof RawData }>
    ),
  })) as unknown as Program[]
}
