import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
export type IconType = FC<HTMLAttributes<SVGElement> & { className?: string }>
export const Svg = styled.svg`
  display: inline-block;
  width: 20px;
`
export const FacebookIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 49.652 49.652"
    className={className}
  >
    <path d="M24.826,0C11.137,0,0,11.137,0,24.826c0,13.688,11.137,24.826,24.826,24.826c13.688,0,24.826-11.138,24.826-24.826 C49.652,11.137,38.516,0,24.826,0z M31,25.7h-4.039c0,6.453,0,14.396,0,14.396h-5.985c0,0,0-7.866,0-14.396h-2.845v-5.088h2.845 v-3.291c0-2.357,1.12-6.04,6.04-6.04l4.435,0.017v4.939c0,0-2.695,0-3.219,0c-0.524,0-1.269,0.262-1.269,1.386v2.99h4.56L31,25.7z"></path>
  </Svg>
)
export const YoutubeIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 97.75 97.75"
    className={className}
  >
  <path d="M42.8,56.7c5.5-2.8,10.9-5.6,16.4-8.5c-5.5-2.9-10.9-5.7-16.4-8.5C42.8,45.4,42.8,51,42.8,56.7z"/>
  <path d="M48.9,0C21.9,0,0,21.9,0,48.9s21.9,48.9,48.9,48.9s48.9-21.9,48.9-48.9S75.9,0,48.9,0z M77.6,62.9c-0.7,3.2-3.3,5.5-6.4,5.8c-7.4,0.8-14.9,0.8-22.3,0.8c-7.4,0-14.9,0-22.3-0.8c-3.1-0.3-5.7-2.7-6.4-5.8c-1-4.5-1-9.4-1-14.1s0-9.6,1-14.1c0.7-3.2,3.3-5.5,6.4-5.8c7.4-0.8,14.9-0.8,22.3-0.8c7.4,0,14.9,0,22.3,0.8c3.1,0.3,5.7,2.7,6.4,5.8c1,4.5,1,9.4,1,14.1S78.6,58.4,77.6,62.9z"/>
  </Svg>
)
export const VKIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 97.75 97.75"
    className={className}
  >
    <path d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z M73.667,54.161c2.278,2.225,4.688,4.319,6.733,6.774c0.906,1.086,1.76,2.209,2.41,3.472c0.928,1.801,0.09,3.776-1.522,3.883 l-10.013-0.002c-2.586,0.214-4.644-0.829-6.379-2.597c-1.385-1.409-2.67-2.914-4.004-4.371c-0.545-0.598-1.119-1.161-1.803-1.604 c-1.365-0.888-2.551-0.616-3.333,0.81c-0.797,1.451-0.979,3.059-1.055,4.674c-0.109,2.361-0.821,2.978-3.19,3.089 c-5.062,0.237-9.865-0.531-14.329-3.083c-3.938-2.251-6.986-5.428-9.642-9.025c-5.172-7.012-9.133-14.708-12.692-22.625 c-0.801-1.783-0.215-2.737,1.752-2.774c3.268-0.063,6.536-0.055,9.804-0.003c1.33,0.021,2.21,0.782,2.721,2.037 c1.766,4.345,3.931,8.479,6.644,12.313c0.723,1.021,1.461,2.039,2.512,2.76c1.16,0.796,2.044,0.533,2.591-0.762 c0.35-0.823,0.501-1.703,0.577-2.585c0.26-3.021,0.291-6.041-0.159-9.05c-0.28-1.883-1.339-3.099-3.216-3.455 c-0.956-0.181-0.816-0.535-0.351-1.081c0.807-0.944,1.563-1.528,3.074-1.528l11.313-0.002c1.783,0.35,2.183,1.15,2.425,2.946 l0.01,12.572c-0.021,0.695,0.349,2.755,1.597,3.21c1,0.33,1.66-0.472,2.258-1.105c2.713-2.879,4.646-6.277,6.377-9.794 c0.764-1.551,1.423-3.156,2.063-4.764c0.476-1.189,1.216-1.774,2.558-1.754l10.894,0.013c0.321,0,0.647,0.003,0.965,0.058 c1.836,0.314,2.339,1.104,1.771,2.895c-0.894,2.814-2.631,5.158-4.329,7.508c-1.82,2.516-3.761,4.944-5.563,7.471 C71.48,50.992,71.611,52.155,73.667,54.161z"></path>
  </Svg>
)
export const TwitterIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 49.652 49.652"
    className={className}
  >
    <path d="M24.826,0C11.137,0,0,11.137,0,24.826c0,13.688,11.137,24.826,24.826,24.826c13.688,0,24.826-11.138,24.826-24.826	C49.652,11.137,38.516,0,24.826,0z M35.901,19.144c0.011,0.246,0.017,0.494,0.017,0.742c0,7.551-5.746,16.255-16.259,16.255	c-3.227,0-6.231-0.943-8.759-2.565c0.447,0.053,0.902,0.08,1.363,0.08c2.678,0,5.141-0.914,7.097-2.446	c-2.5-0.046-4.611-1.698-5.338-3.969c0.348,0.066,0.707,0.103,1.074,0.103c0.521,0,1.027-0.068,1.506-0.199	c-2.614-0.524-4.583-2.833-4.583-5.603c0-0.024,0-0.049,0.001-0.072c0.77,0.427,1.651,0.685,2.587,0.714 c-1.532-1.023-2.541-2.773-2.541-4.755c0-1.048,0.281-2.03,0.773-2.874c2.817,3.458,7.029,5.732,11.777,5.972	c-0.098-0.419-0.147-0.854-0.147-1.303c0-3.155,2.558-5.714,5.713-5.714c1.644,0,3.127,0.694,4.171,1.804	c1.303-0.256,2.523-0.73,3.63-1.387c-0.43,1.335-1.333,2.454-2.516,3.162c1.157-0.138,2.261-0.444,3.282-0.899 C37.987,17.334,37.018,18.341,35.901,19.144z"/>
  </Svg>
)
export const TelegramIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 242.7 242.7"
    className={className}
  >
  <path d="M121.3,0C54.3,0,0,54.3,0,121.3s54.3,121.3,121.3,121.3s121.3-54.3,121.3-121.3S188.3,0,121.3,0z M194,62.8l-24.7,118.7 c-0.6,2.8-3.9,4.1-6.2,2.4l-33.7-24.5c-2-1.5-4.8-1.4-6.8,0.2l-18.7,15.2c-2.2,1.8-5.4,0.8-6.3-1.9l-13-41.7l-33.5-12.5 c-3.4-1.3-3.4-6.1,0-7.4l137.5-53C191.6,57.2,194.6,59.7,194,62.8z"></path>
  <path d="M161.1,84.3l-65.5,40.3c-2.5,1.5-3.7,4.6-2.9,7.4l7.1,24.9c0.5,1.8,3.1,1.6,3.3-0.2l1.8-13.6c0.3-2.6,1.6-4.9,3.5-6.7	l54-50.2C163.5,85.1,162.2,83.5,161.1,84.3z"></path>
  </Svg>
)
export const InstagramIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 49.7 49.7"
    className={className}
  >
  <path d="M24.8,20.1c-2.6,0-4.8,2.1-4.8,4.8c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8C29.6,22.2,27.5,20.1,24.8,20.1z"/>
	<path d="M36.4,18.5c0-2.9-2.4-5.3-5.3-5.3H18.5c-2.9,0-5.3,2.4-5.3,5.3v12.7c0,2.9,2.4,5.3,5.3,5.3h12.7c2.9,0,5.3-2.4,5.3-5.3h0V18.5z M24.8,32.1c-4,0-7.3-3.3-7.3-7.3c0-4,3.3-7.3,7.3-7.3s7.3,3.3,7.3,7.3C32.1,28.8,28.8,32.1,24.8,32.1z M33.7,18.6c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.5,0.2-1,0.5-1.3c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3C34.2,17.7,34,18.2,33.7,18.6z"/>
	<path d="M24.8,0C11.1,0,0,11.1,0,24.8c0,13.7,11.1,24.8,24.8,24.8c13.7,0,24.8-11.1,24.8-24.8C49.7,11.1,38.5,0,24.8,0z M38.9,31.2c0,4.3-3.5,7.8-7.8,7.8H18.5c-4.3,0-7.8-3.5-7.8-7.8V18.5c0-4.3,3.5-7.8,7.8-7.8h12.7c4.3,0,7.8,3.5,7.8,7.8V31.2z"/></Svg>
)
export const OKIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 97.75 97.75"
    className={className}
  >
    <path d="M48.921,40.507c4.667-0.017,8.384-3.766,8.367-8.443c-0.017-4.679-3.742-8.402-8.411-8.406 c-4.708-0.005-8.468,3.787-8.432,8.508C40.48,36.826,44.239,40.524,48.921,40.507z"/>
    <path d="M48.875,0C21.882,0,0,21.883,0,48.875S21.882,97.75,48.875,97.75S97.75,75.867,97.75,48.875S75.868,0,48.875,0z M48.945,14.863c9.52,0.026,17.161,7.813,17.112,17.438c-0.048,9.403-7.814,17.024-17.318,16.992	c-9.407-0.032-17.122-7.831-17.066-17.253C31.726,22.515,39.445,14.837,48.945,14.863z M68.227,56.057 c-2.105,2.161-4.639,3.725-7.453,4.816c-2.66,1.031-5.575,1.55-8.461,1.896c0.437,0.474,0.642,0.707,0.914,0.979 c3.916,3.937,7.851,7.854,11.754,11.802c1.33,1.346,1.607,3.014,0.875,4.577c-0.799,1.71-2.592,2.834-4.351,2.713	c-1.114-0.077-1.983-0.63-2.754-1.407c-2.956-2.974-5.968-5.895-8.862-8.925c-0.845-0.882-1.249-0.714-1.994,0.052 c-2.973,3.062-5.995,6.075-9.034,9.072c-1.365,1.346-2.989,1.59-4.573,0.82c-1.683-0.814-2.753-2.533-2.671-4.262	c0.058-1.166,0.632-2.06,1.434-2.858c3.877-3.869,7.742-7.75,11.608-11.628c0.257-0.257,0.495-0.53,0.868-0.93	c-5.273-0.551-10.028-1.849-14.099-5.032c-0.506-0.396-1.027-0.778-1.487-1.222c-1.783-1.711-1.962-3.672-0.553-5.69	c1.207-1.728,3.231-2.19,5.336-1.197c0.408,0.191,0.796,0.433,1.168,0.689c7.586,5.213,18.008,5.356,25.624,0.233	c0.754-0.576,1.561-1.05,2.496-1.289c1.816-0.468,3.512,0.201,4.486,1.791C69.613,52.874,69.6,54.646,68.227,56.057z"/>
</Svg>
)
export const MailIcon: IconType = ({ className = '' }) => (
  <Svg  style={{width: "18px"}}
    viewBox="0 0 18 15"
    className={className}
  >
    <path d="M16.2 14.5099H1.8C0.805887 14.5099 0 13.704 0 12.7099V1.83156C0.0419475 0.868157 0.83568 0.108952 1.8 0.109864H16.2C17.1941 0.109864 18 0.915752 18 1.90986V12.7099C18 13.704 17.1941 14.5099 16.2 14.5099ZM1.8 3.59106V12.7099H16.2V3.59106L9 8.38986L1.8 3.59106ZM2.52 1.90986L9 6.22987L15.48 1.90986H2.52Z"/>
</Svg>
)
export const URLIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 20 20"
    className={className}
  >
  <path d="M4.79118 20.0001C3.51206 20.0001 2.30737 19.501 1.40317 18.5939C0.498966 17.6896 0 16.4875 0 15.2082C0 13.9289 0.498966 12.7268 1.40317 11.8225L4.3501 8.87516C4.53205 8.69319 4.7774 8.59118 5.03377 8.59118C5.2929 8.59118 5.53549 8.69319 5.71744 8.87516C5.89938 9.05712 6.00138 9.29975 6.00138 9.55892C6.00138 9.81808 5.89938 10.0607 5.71744 10.2427L2.7705 13.19C2.23294 13.7304 1.93522 14.4473 1.93522 15.2082C1.93522 15.9692 2.23294 16.686 2.7705 17.2264C3.31082 17.7668 4.02757 18.0646 4.79118 18.0646C5.55479 18.0646 6.27154 17.7696 6.8091 17.2292L10.6327 13.4051C11.173 12.8647 11.468 12.1478 11.4652 11.3869C11.4652 10.6232 11.1675 9.90631 10.6272 9.36592C10.5252 9.26391 10.4066 9.16465 10.2495 9.05437C10.0372 8.90548 9.89938 8.68216 9.85527 8.42851C9.81116 8.17485 9.86906 7.91844 10.0179 7.70615C10.1999 7.44974 10.4948 7.2981 10.8091 7.2981C11.0103 7.2981 11.2033 7.35875 11.366 7.47455C11.6141 7.651 11.8153 7.81643 11.9945 7.9984C12.8987 8.90273 13.3977 10.1076 13.3977 11.3841C13.4032 12.6634 12.9042 13.8655 11.9972 14.7753L8.17919 18.5994C7.27498 19.5038 6.07305 20.0001 4.79393 20.0001H4.79118Z"/>
  <path d="M9.18902 12.702C8.98778 12.702 8.79481 12.6413 8.63216 12.5255C8.38406 12.349 8.18281 12.1836 8.00363 12.0017C8.00363 12.0017 7.96503 11.9603 7.95952 11.9548C7.85752 11.8473 7.76379 11.748 7.67834 11.6432C7.65628 11.6157 7.63699 11.5881 7.61493 11.5605L7.58736 11.5247C7.51569 11.4309 7.44953 11.3455 7.39164 11.2572C7.37785 11.2379 7.36683 11.2186 7.3558 11.1993C7.27585 11.0698 7.20694 10.9595 7.14629 10.8464C6.78516 10.1489 6.5977 9.38517 6.5977 8.61869C6.59219 7.3394 7.09115 6.1373 7.99812 5.22746L11.8217 1.40061C12.7259 0.499035 13.9278 0 15.2069 0C16.4916 0 17.6935 0.499035 18.5977 1.40612C19.5019 2.31045 20.0009 3.51254 20.0009 4.79184C20.0009 6.07113 19.5019 7.27323 18.5977 8.17756L15.6508 11.1249C15.4688 11.3069 15.2235 11.4089 14.9671 11.4089C14.708 11.4089 14.4654 11.3069 14.2834 11.1249C14.1015 10.9429 13.9995 10.7003 13.9995 10.4411C13.9995 10.182 14.1015 9.93934 14.2834 9.75737L17.2304 6.81004C17.7707 6.26964 18.0657 5.5528 18.0657 4.79184C18.0657 4.03088 17.7679 3.31403 17.2304 2.77364C16.6901 2.23325 15.9733 1.93548 15.2097 1.93548C14.4544 1.93548 13.7431 2.22498 13.2083 2.75434L9.37096 6.59223C8.56324 7.40005 8.31514 8.60766 8.73967 9.6719C8.77275 9.75737 8.80859 9.82906 8.84167 9.89799L8.85821 9.93659C8.88854 9.99724 8.91886 10.0469 8.95194 10.0965L9.00707 10.1902C9.03189 10.2261 9.0567 10.2592 9.08426 10.295L9.14215 10.3722C9.15318 10.3888 9.16421 10.4053 9.17799 10.4191C9.22485 10.477 9.27723 10.5294 9.32685 10.5845L9.35718 10.6203C9.4702 10.7361 9.5915 10.8354 9.74588 10.9457C10.1814 11.2545 10.2862 11.8583 9.97744 12.2939C9.80101 12.5476 9.50328 12.702 9.18902 12.702Z"/>
  </Svg>
)
