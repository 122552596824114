import styled from 'styled-components/macro'
import { chooseColor } from 'utils'
import { screen } from 'styles/mq'
import { colors } from 'styles/colors'
import { motion } from 'framer-motion'

export const HomeTitle = styled.h1`
  display: block;
  margin-top: 104px;
  margin-bottom: 0;
  padding: 35px 0 0;
  font-size: 72px;
  text-transform: uppercase;
  letter-spacing: 6px;
  @media (max-width: ${screen.m}) {
    margin-top: 84px;
  }
  @media (max-width: 719px) {
    font-size: 54px;
  }
  @media (max-width: 499px) {
    font-size: 42px;
  }
  @media (max-width: ${screen.xs}) {
    margin-top: 64px;
    font-size: 36px;
  }
  @media (max-width: 300px) {
    margin-top: 64px;
    font-size: 28px;
  }
`
export const MainMenuContainer = styled(motion.div)<{ $isMenuActive: boolean }>`
  padding-top: 20px;
  padding-bottom: 60px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 1240px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 819px) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 8px;
  }
  @media (max-width: 549px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
  }
  @media (max-width: ${screen.xs}) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  @media (min-width: ${screen.l}) {
    min-height: 100vh;
    align-content: start;
  }
  @media (min-width: 820px) and (max-width: 1240px) {
    div:nth-child(4n) {
      transition: box-shadow 1s;
      box-shadow: ${(props) => (props.$isMenuActive ? 'none' : '')};
    }
  }
  @media (min-width: 1241px) {
    div:nth-child(5n) {
      transition: box-shadow 1s;
      box-shadow: ${(props) => (props.$isMenuActive ? 'none' : '')};
    }
  }
`

export const MainMenuItem = styled(motion.div)`
  position: relative;
  border-radius: 28px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(56, 56, 56, 0.15);
  @media (max-width: ${screen.xs}) {
    border-radius: 15px;
    > svg {
      display: none;
    }
  }
  a {
    //position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    @media (max-width: ${screen.xs}) {
      position: relative;
      height: 76px;
      svg {
        height: 76px;
      }
    }
    > div {
      position: relative;
      @media (max-width: ${screen.xs}) {
        height: 76px;
        float: left;
      }
    }
    hr {
      margin: 0;
      height: 8px;
      border: none;
      @media (max-width: ${screen.xs}) {
        height: 100%;
        width: 6px;
        float: left;
        margin-right: 16px;
      }
    }
    p {
      margin: 0;
      padding: 20px 20px 32px 20px;
      color: ${colors.black};
      font-size: 21px;
      font-weight: 600;
      text-transform: uppercase;
      @media (max-width: 1440px) {
        font-size: 19px;
      }
      @media (max-width: 1239px) {
        font-size: 17px;
      }
      @media (max-width: 1023px) {
        padding: 16px 16px 24px 16px;
        font-size: 15px;
      }
      @media (max-width: 919px) {
        padding: 12px 12px 20px 12px;
        font-size: 15px;
      }
      @media (max-width: 819px) {
        padding: 16px 16px 24px 16px;
        font-size: 16px;
      }
      @media (max-width: 759px) {
      }
      @media (max-width: 669px) {
        padding: 12px 12px 20px 12px;
        font-size: 14px;
      }
      @media (max-width: 549px) {
        padding: 16px 16px 24px 16px;
        font-size: 17px;
      }
      @media (max-width: 431px) {
        padding: 12px 12px 20px 12px;
        font-size: 14px;
      }
      @media (max-width: ${screen.xs}) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 98px;
        font-size: 14px;
        padding: 0 16px 0 0;
      }
      span {
        display: block;
        font-size: 15px;
        font-weight: 400;
        @media (max-width: 1023px) {
          font-size: 13px;
        }
        @media (max-width: ${screen.xs}) {
          font-size: 12px;
        }
      }
    }
  }
`
export const MainMenuPhoto = styled.div`
  > div {
    position: absolute;
    display: flex;
    top: 0;
    height: 100%;
    width: 100%;
  }
  img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
  }
`

export const Hr = styled.hr<{ themaCode: string }>`
  background-color: ${(props) => chooseColor(props.themaCode)};
`
