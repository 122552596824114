import { FC } from 'react'
import { InfoPageWrapper, useInfoPage } from './infoPageWrapper'

type Props = {
  menuActive: boolean
  setMenuActive: (a: boolean) => void
}

const SatelliteData: FC<Props> = (props) => {
  const pageInfo = useInfoPage(102)

  return (
    <InfoPageWrapper {...props} offset={102}>
      <ul className="satellite_data">
        <li>
          <p
            dangerouslySetInnerHTML={{
              __html: pageInfo?.description[0],
            }}
          ></p>
        </li>
      </ul>
      <ul className="satellite_data">
        <li>
          <p
            dangerouslySetInnerHTML={{
              __html: pageInfo?.description[1],
            }}
          ></p>
        </li>
      </ul>
    </InfoPageWrapper>
  )
}

export default SatelliteData
