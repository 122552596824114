import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
export type IconType = FC<HTMLAttributes<SVGElement> & { className?: string }>
export const Svg = styled.svg`
  display: inline-block;
`
export const Logo: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 190.7 61.4"
    className={className}
  >
  <path d="M9.6,32.5l-7.8,4.6c-0.6,0.3-0.9,1-0.7,1.6l0.2,0.8c0.1,0.3,0.2,0.5,0.2,0.8 C3,44.9,5.6,49,8.9,52.4c5.3,5.3,12.1,8.4,19.5,8.9c0.7,0.1,1.5,0.1,2.2,0.1h0c0.1,0,0.2,0,0.3,0c8.1-0.1,15.7-3.2,21.4-9	c3.4-3.4,5.9-7.5,7.4-12.1c0.1-0.3,0.2-0.5,0.2-0.8l0.2-0.8c0.2-0.6-0.1-1.3-0.7-1.6l-7.8-4.6c-0.4-0.2-0.9-0.3-1.4-0.1	c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-0.1,0.9-0.2,1.4c-0.3,1.4-0.7,2.7-1.3,4l0,0 c-3,6.7-9.8,11.4-17.6,11.4c-9.3,0-17.1-6.6-18.9-15.4c-0.1-0.6-0.2-0.9-0.2-1.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.3-0.3-0.5-0.4 C10.5,32.2,10,32.3,9.6,32.5z" />
  <path d="M9.6,28.9l-7.8-4.6c-0.6-0.3-0.9-1-0.7-1.6l0.2-0.8c0.1-0.3,0.2-0.5,0.2-0.8 C3,16.5,5.6,12.4,8.9,9c5.3-5.3,12.1-8.4,19.5-8.9C29.2,0,29.9,0,30.6,0h0c0.1,0,0.2,0,0.3,0C39,0.1,46.6,3.3,52.4,9	c3.4,3.4,5.9,7.5,7.4,12.1c0.1,0.3,0.2,0.5,0.2,0.8l0.2,0.8c0.2,0.6-0.1,1.3-0.7,1.6l-7.8,4.6c-0.4,0.2-0.9,0.3-1.4,0.1	c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.6-0.1-0.9-0.2-1.4c-0.3-1.4-0.7-2.7-1.3-4l0,0 c-3-6.7-9.8-11.4-17.6-11.4c-9.3,0-17.1,6.6-18.9,15.4c-0.1,0.6-0.2,0.9-0.2,1.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.4 C10.5,29.1,10,29.1,9.6,28.9z" />
  <path d="M20.9,17.3c-0.2,0.2,0,0.6,0.3,1.1l8.6,11.3c0.3,0.4,0.5,0.6,0.6,0.9l0,0	c0,0.3-0.3,0.6-0.6,0.9L21.2,43c-0.3,0.5-0.5,0.8-0.3,1.1c0.2,0.3,0.8,0,1.2-0.2l21-12.2l0.9-0.5c0.4-0.2,0.5-0.5,0-0.8l-0.9-0.5 l-21-12.2C21.8,17.3,21.2,17,20.9,17.3z" />
  <polygon points="66.1,11.4 66.1,29.3 70.9,29.3 70.9,22.2 74.7,22.2 74.7,29.3 79.5,29.3 79.5,11.4 74.7,11.4 74.7,18.1 70.9,18.1 70.9,11.4"></polygon>
  <path d="M88.1,23.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3-0.1,0.4c-0.2,0.5-0.4,0.9-0.8,1.1 c-0.4,0.2-0.7,0.4-1.1,0.4c-0.2,0-0.5-0.1-0.8-0.2c-0.3-0.2-0.5-0.4-0.6-0.8c-0.2-0.4-0.3-0.8-0.3-1.4c0-0.6,0.1-1.1,0.4-1.5 c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.4,1.2-0.4c0.4-0.1,0.8-0.1,1.2-0.1V23.6z M92.9,29c-0.1-0.6-0.2-1.2-0.2-1.9 c0-0.7-0.1-1.4-0.1-2.1v-6.5c0-0.9-0.1-1.8-0.2-2.6c-0.1-0.9-0.4-1.6-0.9-2.3c-0.4-0.7-1-1.2-1.9-1.6c-0.8-0.4-1.9-0.6-3.2-0.6 c-0.8,0-1.6,0.1-2.3,0.2c-0.7,0.1-1.3,0.3-1.8,0.5c-0.5,0.2-1,0.4-1.3,0.6l0.9,3.1c0.5-0.3,1.1-0.6,1.6-0.7	c0.6-0.2,1.2-0.3,1.9-0.3c0.8,0,1.4,0.2,1.8,0.7c0.4,0.4,0.6,1,0.6,1.7v0.4c-1,0-2,0.1-2.9,0.4c-0.9,0.2-1.8,0.6-2.5,1.2 c-0.8,0.5-1.4,1.2-1.8,2C80.2,21.9,80,22.9,80,24c0,1,0.2,1.9,0.6,2.7c0.4,0.8,0.9,1.4,1.6,1.9c0.7,0.4,1.5,0.7,2.3,0.7	c0.8,0,1.5-0.2,2.1-0.5c0.7-0.3,1.2-0.8,1.7-1.5h0.1l0.2,1.7H92.9z"  />
  <path d="M136.8,19.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.6,0-1.1-0.1-1.5-0.4	c-0.5-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.8,0.3-1.4,0.7-1.8c0.5-0.4,1.1-0.6,2-0.6c0.2,0,0.4,0,0.5,0 c0.2,0,0.3,0,0.5,0.1V19.3z M141.3,11.6c-0.6-0.1-1.3-0.2-2.2-0.2c-0.9-0.1-1.8-0.1-2.8-0.1c-0.8,0-1.6,0-2.4,0.1	c-0.8,0.1-1.4,0.2-2.1,0.4c-0.6,0.2-1.1,0.4-1.5,0.7c-0.7,0.4-1.2,1-1.5,1.6c-0.4,0.6-0.5,1.4-0.5,2.2c0,0.8,0.2,1.6,0.6,2.2 c0.4,0.6,0.9,1.1,1.5,1.4c0.6,0.4,1.3,0.6,2.1,0.8v0.1c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.6,0.4-0.9,0.7c-0.4,0.4-0.7,1-1,1.6	c-0.3,0.6-0.5,1.2-0.7,1.9c-0.2,0.6-0.3,1.2-0.5,1.7c-0.1,0.4-0.2,0.7-0.4,1.1c-0.2,0.3-0.3,0.6-0.5,0.9h4.9 c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.7c0.2-0.7,0.4-1.4,0.5-2.2c0.2-0.7,0.4-1.3,0.7-1.9c0.3-0.6,0.6-1,1.1-1.2 c0.2-0.1,0.3-0.2,0.6-0.3c0.2-0.1,0.5-0.1,0.7-0.1h0.4v7h4.6V11.6z" />
  <polygon points="142.5,11.4 142.5,29.1 160.9,29.1 161.1,34 164.7,34 164.9,25.7 163.2,25.6 163.2,11.4 158.6,11.4 158.6,25.2 155.2,25.2 155.2,11.4 150.5,11.4 150.5,25.2 147.1,25.2 147.1,11.4"></polygon>
  <path d="M168.7,18.6c0-0.4,0.1-0.9,0.1-1.4c0.1-0.5,0.2-0.9,0.4-1.3c0.2-0.4,0.4-0.7,0.7-1	c0.3-0.2,0.6-0.4,1-0.4c0.5,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.8,0.6,1.4c0.1,0.6,0.2,1.3,0.2,2H168.7z M176.9,21.9	c0.1-0.2,0.1-0.5,0.1-0.9c0-0.4,0-0.9,0-1.5c0-2.6-0.5-4.7-1.6-6.1c-1-1.4-2.5-2.1-4.4-2.1c-1.3,0-2.5,0.4-3.6,1.1	c-1,0.7-1.9,1.8-2.5,3.2c-0.6,1.4-0.9,3.1-0.9,5c0,1.8,0.3,3.4,0.8,4.7c0.6,1.3,1.4,2.3,2.5,2.9c1.1,0.7,2.4,1,4,1	c0.9,0,1.8-0.1,2.6-0.2c0.8-0.1,1.6-0.3,2.2-0.6l-0.6-3.3c-0.4,0.1-0.9,0.3-1.5,0.4c-0.6,0.1-1.2,0.2-1.8,0.2	c-0.7,0-1.3-0.1-1.9-0.4c-0.6-0.2-1-0.6-1.3-1.2c-0.3-0.5-0.5-1.3-0.5-2.2H176.9z" />
  <path d="M182.3,18.6c0-0.4,0.1-0.9,0.1-1.4c0.1-0.5,0.2-0.9,0.4-1.3c0.2-0.4,0.4-0.7,0.7-1 c0.3-0.2,0.6-0.4,1-0.4c0.5,0,0.8,0.2,1.1,0.5c0.3,0.4,0.5,0.8,0.6,1.4c0.1,0.6,0.2,1.3,0.2,2H182.3z M190.5,21.9	c0.1-0.2,0.1-0.5,0.1-0.9c0-0.4,0-0.9,0-1.5c0-2.6-0.5-4.7-1.5-6.1c-1-1.4-2.5-2.1-4.4-2.1c-1.3,0-2.5,0.4-3.6,1.1	c-1,0.7-1.9,1.8-2.5,3.2c-0.6,1.4-0.9,3.1-0.9,5c0,1.8,0.3,3.4,0.9,4.6c0.6,1.3,1.4,2.2,2.5,2.9c1.1,0.7,2.4,1,4,1	c0.9,0,1.8-0.1,2.6-0.2c0.8-0.1,1.6-0.3,2.2-0.6l-0.6-3.3c-0.4,0.1-0.9,0.3-1.5,0.4c-0.6,0.1-1.2,0.2-1.8,0.2	c-0.7,0-1.3-0.1-1.9-0.4c-0.5-0.2-1-0.6-1.3-1.2c-0.3-0.5-0.5-1.3-0.5-2.2H190.5z" />
  <path d="M70.6,42.3h1.1c0.6,0,1.2,0.1,1.7,0.2c0.5,0.2,0.9,0.4,1.2,0.8c0.3,0.3,0.4,0.8,0.5,1.3	c0,0.6-0.2,1-0.5,1.4c-0.3,0.3-0.7,0.6-1.2,0.7c-0.5,0.1-1,0.2-1.6,0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6-0.1V42.3z M70.6,35.2	c0.4-0.1,0.7-0.1,1.2-0.1c0.8,0,1.5,0.2,2,0.5c0.5,0.3,0.7,0.8,0.7,1.5c0,0.5-0.1,0.9-0.4,1.2c-0.3,0.3-0.6,0.6-1,0.7	c-0.4,0.2-0.9,0.3-1.4,0.3h-1.1V35.2z M66.2,49.8c0.5,0.1,1.1,0.1,1.9,0.1c0.7,0,1.5,0,2.4,0c0.7,0,1.5,0,2.3-0.1	c0.8-0.1,1.6-0.2,2.5-0.4c0.8-0.2,1.5-0.5,2.2-0.9c0.7-0.4,1.2-0.9,1.6-1.5c0.4-0.6,0.6-1.4,0.6-2.3c0-0.7-0.2-1.3-0.5-1.9	c-0.3-0.6-0.8-1.1-1.4-1.4c-0.6-0.4-1.4-0.7-2.2-0.8v-0.1c0.6-0.1,1.2-0.4,1.7-0.7c0.5-0.4,0.9-0.8,1.2-1.4	c0.3-0.6,0.5-1.2,0.5-1.9c0-0.8-0.2-1.4-0.5-2c-0.3-0.5-0.8-0.9-1.3-1.3c-0.6-0.3-1.2-0.6-1.8-0.7c-0.7-0.2-1.4-0.3-2.1-0.3 c-0.7-0.1-1.4-0.1-2-0.1c-0.9,0-1.8,0-2.7,0.1c-0.9,0.1-1.7,0.1-2.4,0.3V49.8z" />
  <path d="M99,39.4c0-0.4,0.1-0.9,0.1-1.4c0.1-0.5,0.2-0.9,0.4-1.3c0.2-0.4,0.4-0.7,0.7-1	c0.3-0.2,0.6-0.4,1-0.4c0.5,0,0.8,0.2,1.1,0.5c0.3,0.4,0.5,0.8,0.6,1.4c0.1,0.6,0.2,1.3,0.2,2H99z M107.2,42.6 c0.1-0.2,0.1-0.6,0.1-0.9c0-0.4,0-0.9,0-1.5c0-2.7-0.5-4.7-1.6-6.1c-1-1.4-2.5-2.1-4.4-2.1c-1.3,0-2.5,0.4-3.6,1.1 c-1,0.7-1.9,1.8-2.5,3.2c-0.6,1.4-0.9,3.1-0.9,5c0,1.8,0.3,3.4,0.8,4.6c0.6,1.3,1.4,2.2,2.5,2.9c1.1,0.7,2.4,1,4,1 c0.9,0,1.8-0.1,2.6-0.2c0.8-0.1,1.6-0.3,2.2-0.6l-0.6-3.3c-0.4,0.1-0.9,0.3-1.5,0.4c-0.6,0.1-1.2,0.2-1.8,0.2	c-0.7,0-1.3-0.1-1.9-0.4c-0.5-0.2-1-0.6-1.3-1.2c-0.3-0.6-0.5-1.3-0.5-2.2H107.2z" />
  <path d="M108.6,49.9h4.3l0.2-6.2c0-0.6,0-1.2,0-1.9c0-0.7,0-1.5,0.1-2.5c0-0.9,0-2,0-3.2h0.1 c0.1,0.5,0.2,1,0.3,1.6c0.1,0.6,0.2,1.3,0.4,2c0.1,0.7,0.3,1.4,0.4,2c0.1,0.6,0.2,1.2,0.4,1.7l1.4,6.2h3.9l1.4-6.1	c0.2-0.9,0.4-1.9,0.6-2.8c0.2-0.9,0.4-1.8,0.5-2.6c0.2-0.8,0.3-1.5,0.4-2h0.1c0,1.2,0.1,2.3,0.1,3.3c0,0.9,0,1.7,0.1,2.4	c0,0.7,0,1.3,0.1,1.9l0.2,6.3h4.5L126.8,32h-6l-1.2,5.7c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.5-0.2,1.1-0.3,1.7c-0.1,0.6-0.3,1.3-0.4,2	h-0.1c-0.2-0.8-0.3-1.4-0.5-2c-0.1-0.6-0.3-1.1-0.4-1.7c-0.1-0.5-0.2-1-0.4-1.5l-1.3-5.7h-6L108.6,49.9z" />
  <path d="M138.4,40.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.6,0-1.1-0.1-1.5-0.4	c-0.4-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.8,0.3-1.4,0.7-1.8c0.5-0.4,1.1-0.6,2-0.6c0.2,0,0.3,0,0.5,0	c0.2,0,0.3,0,0.5,0.1V40.1z M142.9,32.3c-0.6-0.1-1.3-0.2-2.2-0.2c-0.9-0.1-1.8-0.1-2.8-0.1c-0.8,0-1.6,0-2.4,0.1	c-0.8,0.1-1.4,0.2-2.1,0.4c-0.6,0.2-1.1,0.4-1.5,0.7c-0.7,0.4-1.2,1-1.5,1.6c-0.4,0.6-0.5,1.4-0.5,2.2c0,0.8,0.2,1.6,0.6,2.2	c0.4,0.6,0.9,1.1,1.5,1.4c0.6,0.4,1.3,0.6,2.1,0.8v0.1c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.6,0.4-0.9,0.7c-0.4,0.4-0.7,0.9-1,1.6	c-0.3,0.6-0.5,1.2-0.7,1.9c-0.2,0.6-0.3,1.2-0.5,1.8c-0.1,0.4-0.2,0.7-0.4,1.1c-0.2,0.3-0.3,0.6-0.5,0.9h4.9	c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.2-0.7c0.2-0.7,0.4-1.4,0.5-2.1c0.2-0.7,0.4-1.3,0.7-1.9c0.3-0.6,0.6-1,1.1-1.3	c0.2-0.1,0.3-0.2,0.6-0.2c0.2-0.1,0.5-0.1,0.7-0.1h0.4v7h4.6V32.3z" />
  <path d="M85.6,40.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0.6,0,1.1-0.1,1.5-0.4	c0.5-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.4-0.9,0.4-1.4c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.4-1.1-0.6-2-0.6c-0.2,0-0.3,0-0.5,0	c-0.2,0-0.3,0-0.5,0.1V40.1z M81.1,32.3c0.6-0.1,1.3-0.2,2.2-0.2c0.9-0.1,1.8-0.1,2.8-0.1c0.8,0,1.6,0.1,2.4,0.1 c0.8,0.1,1.4,0.2,2.1,0.4c0.6,0.2,1.1,0.4,1.5,0.7c0.7,0.4,1.2,1,1.5,1.6c0.4,0.6,0.5,1.4,0.5,2.2c0,0.8-0.2,2.1-0.6,2.7	c-1.7,3.1-4.8,3.3-7.8,2.9v7.2h-4.6V32.3z" />
  <path d="M121,25.7c-1.2,0-2.5-1.9-2.5-5.4c0-3.9,1.5-5.4,2.5-5.4c1.3,0,2.3,2.3,2.3,5.3	C123.4,24.6,121.8,25.7,121,25.7z M121.2,11.4c-4.5,0-7.3,3.5-7.3,8.9c0,5.6,2.6,9,7,9c2.7,0,7.2-1.2,7.2-9.2	C128.1,14.6,125.5,11.4,121.2,11.4z" />
  <path d="M103.7,11.4h-1.3h-1.7c-3.8,0-7.5,3-7.5,9.4c0,5.2,2.7,8.4,7.1,8.5c1.9,0.1,4-0.7,4-0.7	l-0.8-3.4c0,0-0.7,0.3-1.9,0.4c-2.7,0.1-3.7-2.8-3.7-4.9c0-3.1,1.1-4.9,3.6-5.1c0.6-0.1,2.2-0.1,2.2-0.1h2.4V29h4.7V15.3h3.7v-4 H103.7z" />

  </Svg>
)
