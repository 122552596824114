import { FC, useRef, useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import { sections } from 'config'
import { FiltersContainer, Button } from './styled'
import { useLocal } from 'languages/use-local'

type Props = {
  onClickAll: () => void
  onClickFilter: (code: string) => void
  activeProgramCodes: string[]
}

const Filters: FC<Props> = ({
  onClickFilter,
  onClickAll,
  activeProgramCodes,
}) => {
  const local = useLocal()
  const prev = useRef<HTMLDivElement>(null)
  const next = useRef<HTMLDivElement>(null)
  const [activeSwiper, setActiveSwiper] = useState(false)
  const handleResize = () => {
    if (prev.current?.classList.contains('swiper-button-lock')) {
      setActiveSwiper(false)
    } else {
      setActiveSwiper(true)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <FiltersContainer isActive={activeSwiper}>
      <Swiper
        onUpdate={() => {
          handleResize()
        }}
        slidesPerView="auto"
        draggable
        /*freeMode*/
        navigation={{
          enabled: true,
          prevEl: prev.current,
          nextEl: next.current,
        }}
        modules={[Navigation]}
        slideToClickedSlide={true}
      >
        <SwiperSlide>
          <Button
            className={'buttonAll'}
            onClick={onClickAll}
            isActive={activeProgramCodes.length === 0}
          >
            <span>{local.sections[0]}</span>
          </Button>
        </SwiperSlide>
        {sections.map((section) => (
          <SwiperSlide key={section.id}>
            <Button
              onClick={() => onClickFilter(section.code)}
              key={section.id}
              isActive={activeProgramCodes.includes(section.code)}
              sectionColor={section.color}
            >
              <span>{local.sections[section.id]}</span>
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
      <div ref={prev} className="swiper-button-prev"></div>
      <div ref={next} className="swiper-button-next"></div>
    </FiltersContainer>
  )
}
export default Filters
