import { FC, ReactNode, useEffect } from 'react'
import { ProgramStyle, AboutContainer, LeftColumn, RightColumn } from './styled'
import { Parameters } from 'types'
import { useLocal } from 'languages/use-local'

type Props = {
  menuActive: boolean
  setMenuActive: (a: boolean) => void
  offset: number
  children?: ReactNode | ReactNode[]
}

export const useInfoPage = (index: Props['offset']) => {
  const local = useLocal()
  return local.about.find((p) => p.id === index) as Parameters
}

export const InfoPageWrapper: FC<Props> = ({
  setMenuActive,
  offset,
  children,
}) => {
  const pageContent = useInfoPage(offset)

  const handleResize = () => {
    if (window.innerWidth > 1023) {
      /*1339*/
      setMenuActive(true)
    } else {
      setMenuActive(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!pageContent) {
    return null
  }

  return (
    <AboutContainer>
      <div>
        <ProgramStyle>
          <LeftColumn>
            <svg viewBox="0 0 16 9"></svg>
            <img src={pageContent.photo} alt={pageContent.title} />
          </LeftColumn>
          <RightColumn>
            <div>
              <h1>{pageContent.title}</h1>
              <h2>{pageContent.info}</h2>

              {children}
            </div>
          </RightColumn>
        </ProgramStyle>
      </div>
    </AboutContainer>
  )
}
