import { FC, ReactNode, useEffect, useState } from 'react'
import { Link, Outlet, useParams, useLocation } from 'react-router-dom'
import {
  MenuMask,
  MenuContainer,
  MenuSection,
  MenuItem,
  AboutItem,
  MenuGradient,
} from './styled'
import { sections } from 'config'
import { useLocal } from 'languages/use-local'

type Props = {
  activeProgramCodes: string[]
  menuActive: boolean
  closeMenu: () => void
}

const Menu: FC<Props> = ({ activeProgramCodes, menuActive, closeMenu }) => {
  const local = useLocal()
  const { id } = useParams()
  const location = useLocation()

  const activePrograms = sections.filter(
    (s) =>
      activeProgramCodes.length === 0 || activeProgramCodes.includes(s.code)
  )

  const [topGradient, setTopGradient] = useState(false)
  const [bottomGradient, setBottomGradient] = useState(true)

  const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const containerHeight = event.currentTarget.clientHeight
    const scrollHeight = event.currentTarget.scrollHeight
    const scrollTop = event.currentTarget.scrollTop

    if (scrollTop === 0) {
      setTopGradient(false)
    } else {
      setTopGradient(true)
    }

    if (scrollHeight - containerHeight - scrollTop < 5) {
      setBottomGradient(false)
    } else {
      setBottomGradient(true)
    }
  }

  useEffect(() => {
    if (id) {
    //console.log(id)
      const menuItem = document.querySelector(`#program${id}`)

      if (menuItem) {
        menuItem.scrollIntoView({ block: 'center' })
      }
    }
  }, [id])

  const notCloseMenu = () => {
    console.log("NOT CLOSING MENU")
  }

  return (
    <>
      <MenuMask isActive={menuActive}>
        <MenuContainer
          onScroll={scrollHandler}
          isActive={menuActive}
          onClick={
            window.innerWidth > 1024 && location.pathname != '/'/*1339*/
              ? notCloseMenu
              : closeMenu
          }
        >
          <div>
            <div>
              {local.about.map((about) => (
                <AboutItem
                  key={about.id}
                  isActive={about.id === Number(location.pathname.slice(1))}
                >
                  {/*about.id === Number(id)*/}
                  <Link to={`${about.id}`}>{about.title}</Link>
                </AboutItem>
              ))}
            </div>
            {sections.map((section) => (
              <MenuSection key={section.id} style={{ color: section.color }}>
                {local.sections[section.id]}
                <ul>
                  {local.programs.map(
                    (program) =>
                      program.themaCode === section.code && (
                        <MenuItem
                          id={'program' + program.id.toString()}
                          key={program.id}
                          isActive={program.id == Number(id)}
                        >
                          <Link to={`${program.id}`}>{program.title}</Link>
                        </MenuItem>
                      )
                  )}
                </ul>
              </MenuSection>
            ))}
          </div>
        </MenuContainer>
        <MenuGradient
          className="top_gradient"
          isActive={menuActive}
          isVisible={topGradient}
        ></MenuGradient>
        <MenuGradient
          className="bottom_gradient"
          isActive={menuActive}
          isVisible={bottomGradient}
        ></MenuGradient>
      </MenuMask>
      <Outlet />
    </>
  )
}
export default Menu
