import { FC } from 'react'
import { MailIcon } from '../Icons/SocialIcons'
import { OpenUrlIcon } from '../Icons/OpenUrlIcon'
import { InfoPageWrapper, useInfoPage } from './infoPageWrapper'

type Props = {
  menuActive: boolean
  setMenuActive: (a: boolean) => void
}

const ContactInformation: FC<Props> = (props) => {
  const paramsToLocal = useInfoPage(103)

  if (!paramsToLocal) {
    return null
  }

  return (
    <InfoPageWrapper {...props} offset={103}>
      <ul className="contacts">
        <li>
          <a
            href={'mailto:' + paramsToLocal.description[0]}
            target="_blank"
            rel="noreferrer"
          >
            <MailIcon />
            <span
              dangerouslySetInnerHTML={{
                __html: paramsToLocal.description[0],
              }}
            ></span>
          </a>
        </li>
        <li>
          <a
            href={'mailto:' + paramsToLocal.description[1]}
            target="_blank"
            rel="noreferrer"
          >
            <MailIcon />
            <span
              dangerouslySetInnerHTML={{
                __html: paramsToLocal.description[1],
              }}
            ></span>
          </a>
        </li>
        <li>
          <a
            style={{ marginLeft: '1px' }}
            href={'https://' + paramsToLocal.description[2]}
            target="_blank"
            rel="noreferrer"
          >
            <OpenUrlIcon />
            <span
              dangerouslySetInnerHTML={{
                __html: paramsToLocal.description[2],
              }}
            ></span>
          </a>
        </li>
      </ul>
    </InfoPageWrapper>
  )
}

export default ContactInformation
