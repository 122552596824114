import { GlobalStyle } from './styles/global'
import 'swiper/css/bundle'
import { LocalContextProvider } from 'languages/use-local'
import Routes from 'routes'

const App = () => (
  <LocalContextProvider>
    <GlobalStyle />
    <Routes />
  </LocalContextProvider>
)

export default App
