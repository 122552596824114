import { Texts } from './types'

export const sectionsNames = [
  'Все',
  'Актуальные Темы',
  'Общество',
  'Путешествия',
  'Новости / Аналитика',
  'Документальный сериал',
  'История',
]

const texts: Texts = {
  title: 'КАТАЛОГ 2024',
  url: 'https://www.currenttime.tv/a/28791461.html', //en version for changing
  sections: sectionsNames,
  programs: [],
  about: [
    {
      //About
      id: 100,
      title: 'о нас',
      photo:
        'https://gdb.currenttime.tv/5b28a965-0197-4339-a4d3-4ad5f51e642e_w1280_n.jpg',
      photoPosition: 'center center',
      thema: 'информация',
      themaCode: 'about',
      description: [
        'Телеканал ”Настоящее Время” – круглосуточная телевизионная и цифровая сеть для русскоговорящей аудитории во всем мире. «Настоящее Время» – это альтернатива прокремлевским СМИ. Мы предоставляем аудитории то, что не всегда могут обеспечить местные медиа: новости в прямом эфире без цензуры, непредвзятую аналитику и ответственный обмен мнениями, а также документальные циклы, в центре внимания которых не политика, а реальные люди.',
        'Созданный медиакорпорацией Радио Свободная Европа/Радио Свобода при финансовой поддержке Конгресса США и при участии “Голоса Америки”, телеканал “Настоящее Время” объединяет лучших русскоязычных журналистов, освещающих события в России, Украине, Центральной Азии, Балтии и других странах Европы, США и других регионах мира.',
        'Телеканал “Настоящее Время” – крупнейший медиаресурс с документальными фильмами на русском языке. Мы предлагаем аудитории более 100 кинопремьер в год. Многие из этих фильмов становятся доступными для российской аудитории только благодаря “Настоящему Времени”.',
        'Телеканал “Настоящее Время” – это не только новости, аналитика и документальное кино, но и программы о медиаграмотности, бизнес-начинаниях, малоизвестных уголках русскоговорящего мира, социальных инициативах, образовании, кухне и путешествиях.',
      ],
      url: '',
      info: '',
    },
    {
      id: 101,
      title: 'О ПЛАТФОРМАХ',
      photo:
        'https://gdb.currenttime.tv/9a0dc41d-96e6-4304-b3f5-30fbc7d2e0b0_w1280_n.jpg',
      photoPosition: 'center center',
      thema: 'информация',
      themaCode: 'about',
      description: [
        'КРУГЛОСУТОЧНЫЙ ТЕЛЕКАНАЛ “НАСТОЯЩЕЕ ВРЕМЯ”',
        'ЕЖЕДНЕВНАЯ НОВОСТНАЯ РАССЫЛКА 7:40',
        'MОБИЛЬНОЕ ПРИЛОЖЕНИЕ “НАСТОЯЩЕЕ ВРЕМЯ”',
        'СОЦИАЛЬНЫЕ СЕТИ',
      ],
      url: '',
      info: '',
    },
    {
      id: 102,
      title: 'информация',
      photo:
        'https://gdb.currenttime.tv/066ed60e-a90e-4baf-bb7c-800b96a75188_w1280_n.jpg',
      photoPosition: 'bottom center',
      thema: 'информация',
      themaCode: 'about',
      description: [
        'СПУТНИК: HOT BIRD 13B 13° EAST<br> ЧАСТОТА: 12,226 ГГЦ<br> СКОРОСТЬ МОДУЛЯЦИИ: 27500 MS<br> ПОЛЯРИЗАЦИЯ: ВЕРТИКАЛЬНАЯ FEC: ¾<br> ВИРТУАЛЬНЫЙ КАНАЛ: 200<br> ВИДЕО PID: 1760<br> АУДИО PID: 1720',
        '<h3>С 19.00 ДО 7.00 (МСК)</h3> СПУТНИК: ASTRA 5B 31,5° EAST<br> ЧАСТОТА: 11,817 ГГЦ (TURAN TV)<br> СКОРОСТЬ МОДУЛЯЦИИ: 27500 MS<br> ПОЛЯРИЗАЦИЯ: ВЕРТИКАЛЬНАЯ FEC: ¾<br> СПУТНИК: EUTELSAT 36B 36° EAST<br> ЧАСТОТА: 11,212 ГГЦ<br> СКОРОСТЬ МОДУЛЯЦИИ: 14400 MS<br> ПОЛЯРИЗАЦИЯ: ГОРИЗОНТАЛЬНАЯ FEC: 3/5<br> ВИРТУАЛЬНЫЙ КАНАЛ: 5002<br> Видео PID: 1860<br> АУДИО PID: 1820',
      ],
      url: '',
      info: '',
    },
    {
      id: 103,
      title: 'КОНТАКТНАЯ ИНФОРМАЦИЯ',
      photo:
        'https://gdb.currenttime.tv/bcc31c80-4fb3-41c9-a385-37cdee06ac4e_w1280_n.jpg',
      photoPosition: 'center center',
      thema: 'информация',
      themaCode: 'about',
      description: [
        'distribution@currenttime.tv',
        'aliyevk@currenttime.tv',
        'www.currenttime.tv',
      ],
      url: '',
      info: '',
    },
  ],
  others: {
    openShowPage: 'На страницу',
  },
  socialMedia: {
    facebook: 'facebook.com/currenttimetv',
    youtube: 'youtube.com/user/currenttimetv',
    vk: 'vk.com/currenttime',
    twitter: 'twitter.com/currenttimetv',
    telegram: 'telegram.me/currenttime',
    instagram: 'instagram.com/currenttime.tv',
    ok: 'ok.ru/currenttime',
  },
}

export default texts
