import styled from 'styled-components/macro'
import { screen } from 'styles/mq'
import { colors } from 'styles/colors'

export const MenuMask = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 103px;
  height: calc(100% - 103px);
  @media (max-width: ${screen.m}) {
    top: 83px;
    height: calc(100% - 83px);
  }
  @media (max-width: ${screen.xs}) {
    top: 63px;
    height: calc(100% - 63px);
  }
  transition: all 1s;
  right: calc((100% - 1600px) / 2 - 1px);
  max-width: 100%;
  overflow: hidden;
  z-index: 4;
  width: ${(props) => (props.isActive ? '317px' : '0px')};
  @media (max-width: ${screen.xxl}) {
    right: calc((100% - 1440px) / 2 - 1px);
    width: ${(props) => (props.isActive ? '285px' : '0px')};
  }
  @media (max-width: ${screen.xl}) {
    right: 0;
    width: ${(props) => (props.isActive ? 'calc(100vw / 5)' : '0px')};
  }
  @media (max-width: 1240px) {
    width: ${(props) => (props.isActive ? 'calc(100vw / 4)' : '0px')};
  }
  @media (max-width: 1024px) {
    width: 100%;
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    overflow: visible;
  }
`
export const MenuContainer = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  top: 0px;
  width: 317px;
  //width: calc(1600px / 5);
  @media (max-width: ${screen.xxl}) {
    width: 285px;
    //width: calc(1440px / 5);
  }
  @media (max-width: ${screen.xl}) {
    width: calc(100vw / 5);
  }
  @media (max-width: 1240px) {
    width: calc(100vw / 4);
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
  height: 100%;
  max-height: 100%;
  background: ${colors.white};
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    width: 100%;
    padding: 20px 20px 32px 20px;
    @media (min-width: 640px) and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      //display: grid;
      //grid-template-columns: 1fr 1fr;
      //height: 100%;
      max-height: 1024px;
      max-width: 640px;
      margin: auto;
    }
  }
  > a {
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 28px;
    color: ${colors.black};
    text-decoration: none;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
`

export const MenuSection = styled.div`
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  ul {
    padding-left: 20px;
    list-style-type: none;
    margin-top: 5px;
    margin-bottom: 20px;
  }
`
export const MenuItem = styled.li<{ isActive: boolean }>`
  font-size: 16px;
  line-height: 21px;
  padding: 4px 0;
  list-style-type: ${(props) => (props.isActive ? 'disc' : 'none')};
  a {
    color: ${(props) => (props.isActive ? 'inherit' : colors.black)};
    &:hover {
      color: inherit;
    }
    text-decoration: none;
    font-weight: 300;
    text-transform: capitalize;
  }
`
export const AboutItem = styled.li<{ isActive: boolean }>`
  list-style-type: none;
  &:last-of-type {
    margin-bottom: 20px;
  }
  a {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 28px;
    color: ${colors.black};
    text-decoration: none;
    font-weight: ${(props) => (props.isActive ? '600' : '300')};
    &:hover {
      font-weight: 600;
    }
  }
`
export const MenuGradient = styled.div<{
  isActive: boolean
  isVisible: boolean
}>`
  position: absolute;
  right: 0;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  &.top_gradient {
    top: -1px;
    background: linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0)
    );
  }
  &.bottom_gradient {
    bottom: 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 1)
    );
  }
  width: 284px;
  width: calc(100vw / 5);
  @media (max-width: 1240px) {
    width: calc(100vw / 4);
  }
  height: 56px;
  transition: transform 1s;
  transform: ${(props) =>
    props.isActive ? 'translateX(0%)' : 'translateX(100%)'};
  @media (max-width: 1024px) {
    width: 100%;
  }
`
