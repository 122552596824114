import { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
export type IconType = FC<HTMLAttributes<SVGElement> & { className?: string }>
export const Svg = styled.svg`
  display: inline-block;
  width: 40px;
`
export const MenuIcon: IconType = ({ className = '' }) => (
  <Svg
    viewBox="0 0 40 40"
    className={className}
  >
    <line x1="1" y1="12" x2="39" y2="12" stroke="#383838" strokeWidth="2" strokeLinecap="round" />
    <line x1="1" y1="22" x2="39" y2="22" stroke="#383838" strokeWidth="2" strokeLinecap="round" />
    <line x1="1" y1="32" x2="39" y2="32" stroke="#383838" strokeWidth="2" strokeLinecap="round" />
</Svg>
)
